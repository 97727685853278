import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import {
  ARBITRUM_GOVERNANCE_USDC_TREASURY,
  ETHEREUM_GOVERNANCE_USDC_TREASURY,
} from "../components/dashboard/insurance-fund-positions/InsuranceFundPositions";
import { PublicKey } from "@solana/web3.js";
import { DASHBOARD_API_ENDPOINT } from "../init/constants";
import { AccountLayout, u64 } from "@solana/spl-token";
import { nativeToUi } from "../utils/amount";

// /** FUND POSITIONS VALUES ON 2023/12/09 */
// const IF_BALANCE = 47_864_438;
// const MAPLE_FINANCE_USDC_SOLANA = 6_966_427;
// const ORCA_UXD_USDC_WHIRLPOOL = 1_450_864;
// const SABER_UXD_USDC_LP = 1_799_730;

// const TOTAL_INSURANCE_FUND_231209 = 52_306_546;
// /** GOVERNANCE ACCOUNTS VALUES ON 2023/12/09 */
// const GA_Balance = 4_441_991;
// const REALMS_USDC_TREASURY = 435_144;
// const REALMS_UXD_TREASURY = 4_000_001;
// const REALMS_USDC_ATA = 3_754;
// const TALLY_USDC_TREASURY = 1_835;
// const GNOSIS_USDC_TREASURY = 1_259;

// const SONAR_ENDPOINT_V1 = "https://portfolio-api.sonar.watch/v1/portfolio/fetch";

const UXP_GOVERNANCE_HOT_WALLET =
  "7M6TSEkRiXiYmpRCcCDSdJGTGxAPem2HBqjW4gLQ2KoE";

const getEthereumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // USDC
    // https://etherscan.io/address/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "homestead",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ETHEREUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getArbitrumUSDCDeposit = async () => {
  const abi = [
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];

  const contract = new ethers.Contract(
    // Bridged USDC
    // https://arbiscan.io/token/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    abi
  );
  const provider = new ethers.providers.InfuraProvider(
    "arbitrum",
    "1b07c06cf0494e879d4eaa6f555cbd7f"
  );
  const balance = await contract
    .connect(provider)
    .balanceOf(ARBITRUM_GOVERNANCE_USDC_TREASURY);

  return Number(ethers.utils.formatUnits(balance ?? 0, 6));
};

const getMultipleTokenAccountBalances = async (
  accounts: { pubkey: PublicKey; decimals: number }[]
): Promise<(number | null)[]> => {
  const accountsInfo =
    await window.__UXD__.solana.connection.getMultipleAccountsInfo(
      accounts.map(({ pubkey }) => pubkey)
    );

  return accountsInfo.map((accountInfo, i) => {
    if (!accountInfo) return null;

    const nativeAmount = u64.fromBuffer(
      AccountLayout.decode(accountInfo.data).amount
    );

    return nativeToUi(nativeAmount, accounts[i].decimals);
  });
};

const useInsuranceFundPositions = () => {
  const USDCMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("USDC").mint.toBase58(),
    []
  );
  const UXDMintAddress = useMemo(
    () => window.__UXD__.solana.config.getMintInfo("UXD").mint.toBase58(),
    []
  );

  const findValueByPlatform = (sonarData: any, platform: string) =>
    sonarData?.elements?.find((elt: any) => elt.platformId === platform).value;

  const [positions, setPositions] = useState<{
    solana: {
      USDCVaultHolding: number | null;
      USDCHotWalletATA: number | null;
      SaberUXDUSDCPool: number | null;
      UXDVaultHolding: number | null;
      UXDHotWalletATA: number | null;
      UXDProgramAuthorityATA: number | null;
      OrcaWhirlpools: number | null;
      MapleFinanceUSDC: number | null;
    };
    ethereum: {
      USDCVaultHolding: number | null;
    };
    arbitrum: {
      USDCVaultHolding: number | null;
    };
  } | null>(null);

  useEffect(() => {
    const fetchInsuranceFundPositions = async () => {
      const mapleGov = "9uM8UiGnpbVUUo3XMiESD54PDQbdLcwdunqQMebaFu2r";

      // Launch all requests in parallel
      const ethereumUSDCDepositPromise = getEthereumUSDCDeposit();
      const arbitrumUSDCDepositPromise = getArbitrumUSDCDeposit();

      const accountsBalancesPromise = getMultipleTokenAccountBalances([
        {
          // quarryUXDUSDCSaberLPToken
          pubkey: new PublicKey("uMTKu14LJph1sv9FD27LfsA5V6KNmnnt4ZLzdw1pcxi"),
          decimals: 6,
        },
        {
          // UXDUSDCSaberUXDVault
          pubkey: new PublicKey("9zj4aX38Uxf3h6AUjS4EipWS8mwbEofasHAf3a1uKGds"),
          decimals: 6,
        },
        {
          // UXDUSDCSaberUSDCVault
          pubkey: new PublicKey("CwQDG1MWunn9cLNwcZLd8YBacweSR7ARo32w4mLua1Yr"),
          decimals: 6,
        },
        {
          // UXDProgramAuthorityATA
          pubkey: new PublicKey("REFVReVTe9cYMgxpZQ3NJ7LWRLPjPUFoQPZUcA3MezQ"),
          decimals: 6,
        },
        {
          // UXDVaultHolding
          pubkey: new PublicKey("BBUcWRZKTyByVzm9YpJsQxeWzecZbhUbr5EpW4VS8oW6"),
          decimals: 6,
        },
        {
          // UXDHotWalletATA
          pubkey: new PublicKey("GwSLedXAznA4NfvnSALGsuHG2hgope3vUYJZCXog1vu4"),
          decimals: 6,
        },
        {
          // USDCHotWalletATA
          pubkey: new PublicKey("CzztF4jyKfRQU6J4eCXhZEpMsg6iZAKtQjgQEoKrKNvZ"),
          decimals: 6,
        },
        {
          // USDCVaultHolding
          pubkey: new PublicKey("9DG795GEYQ7byWz9HAbWkw7Q7W39P3hS614VDRobiAHh"),
          decimals: 6,
        },
      ]);

      const UXDUSDCSaberPoolLpTokenTotalSupplyPromise =
        window.__UXD__.solana.connection.getTokenSupply(
          new PublicKey("UXDgmqLd1roNYkC4TmJzok61qcM9oKs5foDADiFoCiJ")
        );

      const [responseV1HotWallet, responseV1Maple] = await Promise.all([
        fetch(
          `${DASHBOARD_API_ENDPOINT}/api/sonar?address=${UXP_GOVERNANCE_HOT_WALLET}&system=solana`
        ),
        fetch(
          `${DASHBOARD_API_ENDPOINT}/api/sonar?address=${mapleGov}&system=solana`
        ),
      ]);

      const [dataHotWallet, dataMaple] = await Promise.all([
        responseV1HotWallet.json(),
        responseV1Maple.json(),
      ]);

      // Divide by 2 because we don't count UXP in the UXD/UXP pool
      const orcaAmount = (findValueByPlatform(dataHotWallet, "orca") ?? 0) / 2;
      const mapleAmount = findValueByPlatform(dataMaple, "maple");

      // Wait it all
      const [
        ethereumUSDCDeposit,
        arbitrumUSDCDeposit,
        [
          quarryUXDUSDCSaberLPTokenBalance,
          UXDUSDCSaberUXDVaultBalance,
          UXDUSDCSaberUSDCVaultBalance,
          UXDProgramAuthorityATABalance,
          UXDVaultHoldingBalance,
          UXDHotWalletATABalance,
          USDCHotWalletATABalance,
          USDCVaultHoldingBalance,
        ],
        UXDUSDCSaberPoolLpTokenTotalSupply,
      ] = await Promise.all([
        ethereumUSDCDepositPromise,
        arbitrumUSDCDepositPromise,
        accountsBalancesPromise,
        UXDUSDCSaberPoolLpTokenTotalSupplyPromise,
      ]);

      // Calculate saber position size
      const SaberUXDUSDCPool = (() => {
        if (
          !UXDUSDCSaberUXDVaultBalance ||
          !UXDUSDCSaberUSDCVaultBalance ||
          !UXDUSDCSaberPoolLpTokenTotalSupply?.value.uiAmount ||
          !quarryUXDUSDCSaberLPTokenBalance
        ) {
          return 0;
        }

        // Consider 1 USDC = 1 UXD = $1
        const totalValue =
          UXDUSDCSaberUXDVaultBalance + UXDUSDCSaberUSDCVaultBalance;

        // Calculate pool share
        const poolShare =
          totalValue / UXDUSDCSaberPoolLpTokenTotalSupply.value.uiAmount;

        return poolShare * quarryUXDUSDCSaberLPTokenBalance;
      })();

      setPositions({
        solana: {
          UXDVaultHolding: UXDVaultHoldingBalance ?? 0,
          UXDHotWalletATA: UXDHotWalletATABalance ?? 0,
          UXDProgramAuthorityATA: UXDProgramAuthorityATABalance ?? 0,
          USDCHotWalletATA: USDCHotWalletATABalance ?? 0,
          USDCVaultHolding: USDCVaultHoldingBalance ?? 0,
          SaberUXDUSDCPool: SaberUXDUSDCPool ?? 0,
          OrcaWhirlpools: orcaAmount,
          MapleFinanceUSDC: mapleAmount,
        },
        ethereum: {
          USDCVaultHolding: ethereumUSDCDeposit,
        },
        arbitrum: {
          USDCVaultHolding: arbitrumUSDCDeposit,
        },
      });
    };

    fetchInsuranceFundPositions();
  }, [USDCMintAddress, UXDMintAddress]);

  return positions;
};

export default useInsuranceFundPositions;
