import { ReactNode } from "react";
import useInsuranceFundPositions from "../../../hooks/useInsuranceFundPositions";
import Section from "../../common/section/Section";
import PositionRow from "./PositionRow";
import { renderAmount } from "../../../utils/formatting";

import "./InsuranceFundPositions.scss";

function getSolanaExplorerURL(address: string): string {
  return `https://explorer.solana.com/address/${address}`;
}

function getEthereumExplorerURL(address: string): string {
  return `https://etherscan.io/address/${address}`;
}

function getArbitrumExplorerURL(address: string): string {
  return `https://arbiscan.io/address/${address}`;
}

const UXP_GOVERNANCE_USDC_TREASURY_OWNER =
  "9SAveSCmGTVR9csAjK45keGitb1kdsC22Pb1AFdoUcSD";
const UXP_GOVERNANCE_UXD_HOT_WALLET_ATA =
  "GwSLedXAznA4NfvnSALGsuHG2hgope3vUYJZCXog1vu4";
const UXP_GOVERNANCE_USDC_HOT_WALLET_ATA =
  "CzztF4jyKfRQU6J4eCXhZEpMsg6iZAKtQjgQEoKrKNvZ";
const UXP_GOVERNANCE_UXD_TREASURY_OWNER =
  "HL1s4cSbQu8zSG12Ck7YmbmvphQNs6wgZf89FFCGYYbV";
const UXP_GOVERNANCE_UXD_AUTHORITY_OWNER =
  "CzZySsi1dRHMitTtNe2P12w3ja2XmfcGgqJBS8ytBhhY";

export const ETHEREUM_GOVERNANCE_USDC_TREASURY =
  "0x86A07dDED024121b282362f4e7A249b00F5dAB37";

export const ARBITRUM_GOVERNANCE_USDC_TREASURY =
  "0x20f665E8DfFD6ac0678fe310D0BF0Bc1b6cc2A7a";

const InsuranceFundTotalBalance = ({
  balance,
  title,
}: {
  balance?: number;
  title: string;
}) => {
  if (typeof balance === "undefined") {
    return null;
  }

  return (
    <span className="InsuranceFundTotalBalance">
      <strong className="InsuranceFundTotalBalance-title">{title}</strong>
      <strong>{renderAmount(balance)}</strong>
    </span>
  );
};

const InsuranceFundTable = ({
  children: positions,
  title,
  balanceSubtotal,
}: {
  children: ReactNode;
  title: string;
  balanceSubtotal?: number;
}) => (
  <Section title={title}>
    {typeof balanceSubtotal !== "undefined" ? (
      <div className="InsuranceFundBalance">
        <table>
          <colgroup>
            <col />
          </colgroup>

          <thead>
            <tr>
              <th className="InsuranceFundPositions-col--platform">Balance</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{renderAmount(balanceSubtotal)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : null}

    <div className="InsuranceFundPositions">
      <table>
        <colgroup>
          <col className="InsuranceFundPositions-col--blockchain" />
          <col className="InsuranceFundPositions-col--platform" />
          <col className="InsuranceFundPositions-col--position" />
          <col className="InsuranceFundPositions-col--value" />
          <col className="InsuranceFundPositions-col--fund-percentage" />
        </colgroup>

        <thead>
          <tr>
            <th className="InsuranceFundPositions-col--blockchain">
              Blockchain
            </th>
            <th className="InsuranceFundPositions-col--platform">Platform</th>
            <th className="InsuranceFundPositions-col--position">Position</th>
            <th className="InsuranceFundPositions-col--value">Value</th>
            <th className="InsuranceFundPositions-col--fund-percentage">
              Fund %
            </th>
          </tr>
        </thead>

        <tbody>{positions}</tbody>
      </table>
    </div>
  </Section>
);

const InsuranceFundPositions = () => {
  // Dynamic positions
  const positions = useInsuranceFundPositions();

  // Backup static values in case API is down
  const orcaWhirlpoolUXP = 1_588_000;
  const mapleFinanceUSDCSolana = 7_000_000;

  // Static positions
  const fluxFinanceUSDC = 10_200_000;
  const openEdenUSDC = 10_163_935;
  const mapleFinanceUSDCEthereum = 5_194_482;
  const kumaBondUSDC = 2_000_000;
  const sparkVaultSDAI = 8_100_000.5;

  const credixUSDC = 1_989_000;

  const totalFundPositions: number | undefined = positions
    ? (positions?.solana.SaberUXDUSDCPool ?? 0) +
      (positions?.solana.OrcaWhirlpools ?? orcaWhirlpoolUXP) +
      credixUSDC +
      fluxFinanceUSDC +
      openEdenUSDC +
      mapleFinanceUSDCEthereum +
      (positions?.solana.MapleFinanceUSDC ?? mapleFinanceUSDCSolana) +
      kumaBondUSDC +
      sparkVaultSDAI
    : undefined;

  const totalGovernanceAccounts: number | undefined = positions
    ? (positions?.solana.USDCVaultHolding ?? 0) +
      (positions?.solana.UXDVaultHolding ?? 0) +
      (positions?.solana.UXDHotWalletATA ?? 0) +
      (positions?.solana.UXDProgramAuthorityATA ?? 0) +
      (positions?.solana.USDCHotWalletATA ?? 0) +
      (positions?.ethereum.USDCVaultHolding ?? 0) +
      (positions?.arbitrum.USDCVaultHolding ?? 0)
    : undefined;

  const insuranceFundTotalBalance: number | undefined = positions
    ? totalFundPositions! + totalGovernanceAccounts!
    : undefined;

  return (
    <>
      <h3 className="InsuranceFundPositions-title">
        <span>Insurance Fund</span>

        <InsuranceFundTotalBalance
          balance={insuranceFundTotalBalance}
          title="Total"
        />
      </h3>

      <InsuranceFundTable
        title="Fund Positions"
        balanceSubtotal={totalFundPositions}
      >
        <PositionRow
          blockchain="Solana"
          platform="Maple Finance"
          name="CASH MANAGEMENT POOL"
          amount={positions?.solana.MapleFinanceUSDC ?? mapleFinanceUSDCSolana}
          linkHref="https://www.maple.finance/"
          linkTitle="View Maple Finance Website"
        />
        <PositionRow
          blockchain="Ethereum"
          platform="Flux Finance"
          name="USDC LP"
          amount={fluxFinanceUSDC}
          linkHref="https://fluxfinance.com/"
          linkTitle="View Flux Finance Website"
        />
        <PositionRow
          blockchain="Ethereum"
          platform="Maple Finance"
          name="US TREASURY BONDS"
          amount={mapleFinanceUSDCEthereum}
          linkHref="https://www.maple.finance/"
          linkTitle="View Maple Finance Website"
        />
        <PositionRow
          blockchain="Ethereum"
          platform="Spark"
          name="sDai Vault"
          amount={sparkVaultSDAI}
          linkHref="https://app.spark.fi/sdai/"
          linkTitle="View Spark sDAI Vault"
        />
        <PositionRow
          blockchain="Ethereum"
          platform="OpenEden"
          name="TBILL VAULT"
          amount={openEdenUSDC}
          linkHref="https://app.openeden.com/"
          linkTitle="View OpenEden Website"
        />
        <PositionRow
          blockchain="Solana"
          platform="Orca"
          name="UXP-USDC Whirlpool"
          amount={positions?.solana.OrcaWhirlpools ?? orcaWhirlpoolUXP}
          linkHref="https://v1.orca.so/liquidity/browse?tokenMint=UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M"
          linkTitle="View Orca Whirlpools"
        />
        <PositionRow
          blockchain="Solana"
          platform="Credix"
          name="USDC Vault"
          amount={credixUSDC}
          linkHref="https://credix.finance/"
          linkTitle="View Credix Website"
        />
        <PositionRow
          blockchain="Ethereum"
          platform="Kuma Protocol"
          name="US TREASURY BONDS"
          amount={kumaBondUSDC}
          linkHref="https://kuma.bond/"
          linkTitle="View Kuma Protocol Website"
        />
        <PositionRow
          blockchain="Solana"
          platform="Saber"
          name="UXD-USDC LP"
          amount={positions?.solana.SaberUXDUSDCPool ?? null}
          linkHref="https://saber.markets/#/pools/KEN5P7p3asnb23Sw6yAmJRGvijfAzso3RqfyLAQhznt"
          linkTitle="View Saber UXD-USDC Liquidity Pool Stats"
        />
      </InsuranceFundTable>

      <InsuranceFundTable
        title="Governance Accounts"
        balanceSubtotal={totalGovernanceAccounts}
      >
        {positions?.solana.USDCVaultHolding ? (
          <PositionRow
            blockchain="Solana"
            platform="REALMS"
            name="USDC Treasury"
            amount={positions.solana.USDCVaultHolding}
            linkHref={getSolanaExplorerURL(UXP_GOVERNANCE_USDC_TREASURY_OWNER)}
            linkTitle={`View account ${UXP_GOVERNANCE_USDC_TREASURY_OWNER} on Solana Explorer`}
          />
        ) : null}
        {positions?.solana.UXDHotWalletATA ? (
          <PositionRow
            blockchain="Solana"
            platform="REALMS"
            name="UXD ATA"
            amount={positions.solana.UXDHotWalletATA}
            linkHref={getSolanaExplorerURL(UXP_GOVERNANCE_UXD_HOT_WALLET_ATA)}
            linkTitle={`View account ${UXP_GOVERNANCE_UXD_HOT_WALLET_ATA} on Solana Explorer`}
          />
        ) : null}
        {positions?.solana.UXDVaultHolding ? (
          <PositionRow
            blockchain="Solana"
            platform="REALMS"
            name="UXD Treasury"
            amount={positions.solana.UXDVaultHolding}
            linkHref={getSolanaExplorerURL(UXP_GOVERNANCE_UXD_TREASURY_OWNER)}
            linkTitle={`View account ${UXP_GOVERNANCE_UXD_TREASURY_OWNER} on Solana Explorer`}
          />
        ) : null}
        {positions?.solana.UXDProgramAuthorityATA ? (
          <PositionRow
            blockchain="Solana"
            platform="REALMS"
            name="UXD Treasury"
            amount={positions.solana.UXDProgramAuthorityATA}
            linkHref={getSolanaExplorerURL(UXP_GOVERNANCE_UXD_AUTHORITY_OWNER)}
            linkTitle={`View account ${UXP_GOVERNANCE_UXD_AUTHORITY_OWNER} on Solana Explorer`}
          />
        ) : null}
        {positions?.solana.USDCHotWalletATA ? (
          <PositionRow
            blockchain="Solana"
            platform="REALMS"
            name="USDC ATA"
            amount={positions.solana.USDCHotWalletATA}
            linkHref={getSolanaExplorerURL(UXP_GOVERNANCE_USDC_HOT_WALLET_ATA)}
            linkTitle={`View account ${UXP_GOVERNANCE_USDC_HOT_WALLET_ATA} on Solana Explorer`}
          />
        ) : null}
        {positions?.arbitrum.USDCVaultHolding ? (
          <PositionRow
            blockchain="Arbitrum"
            platform="Tally"
            name="USDC Treasury"
            amount={positions.arbitrum.USDCVaultHolding}
            linkHref={getArbitrumExplorerURL(ARBITRUM_GOVERNANCE_USDC_TREASURY)}
            linkTitle={`View account ${ARBITRUM_GOVERNANCE_USDC_TREASURY} on Arbitrum Explorer`}
          />
        ) : null}
        {positions?.ethereum.USDCVaultHolding ? (
          <PositionRow
            blockchain="Ethereum"
            platform="GNOSIS"
            name="USDC Treasury"
            amount={positions.ethereum.USDCVaultHolding}
            linkHref={getEthereumExplorerURL(ETHEREUM_GOVERNANCE_USDC_TREASURY)}
            linkTitle={`View account ${ETHEREUM_GOVERNANCE_USDC_TREASURY} on Ethereum Explorer`}
          />
        ) : null}
      </InsuranceFundTable>
    </>
  );
};

export default InsuranceFundPositions;
